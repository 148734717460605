export const environment = {
  NVM_INC: '/root/.nvm/versions/node/v12.13.1/include/node',
	XDG_SESSION_ID: '2',
	HOSTNAME: 'aval360qas.kukac.com.br',
	NODE: '/root/.nvm/versions/node/v12.13.1/bin/node',
	SELINUX_ROLE_REQUESTED: '',
	INIT_CWD: '/var/www/html/fdc360/front',
	NVM_CD_FLAGS: '',
	TERM: 'xterm-256color',
	SHELL: '/bin/bash',
	HISTSIZE: '1000',
	SSH_CLIENT: '187.20.120.128 49233 9732',
	SELINUX_USE_CURRENT_RANGE: '',
	SSH_TTY: '/dev/pts/1',
	USER: 'kadmin',
	NVM_DIR: '/root/.nvm',
	LS_COLORS: 'rs=0:di=38;5;27:ln=38;5;51:mh=44;38;5;15:pi=40;38;5;11:so=38;5;13:do=38;5;5:bd=48;5;232;38;5;11:cd=48;5;232;38;5;3:or=48;5;232;38;5;9:mi=05;48;5;232;38;5;15:su=48;5;196;38;5;15:sg=48;5;11;38;5;16:ca=48;5;196;38;5;226:tw=48;5;10;38;5;16:ow=48;5;10;38;5;21:st=48;5;21;38;5;15:ex=38;5;34:*.tar=38;5;9:*.tgz=38;5;9:*.arc=38;5;9:*.arj=38;5;9:*.taz=38;5;9:*.lha=38;5;9:*.lz4=38;5;9:*.lzh=38;5;9:*.lzma=38;5;9:*.tlz=38;5;9:*.txz=38;5;9:*.tzo=38;5;9:*.t7z=38;5;9:*.zip=38;5;9:*.z=38;5;9:*.Z=38;5;9:*.dz=38;5;9:*.gz=38;5;9:*.lrz=38;5;9:*.lz=38;5;9:*.lzo=38;5;9:*.xz=38;5;9:*.bz2=38;5;9:*.bz=38;5;9:*.tbz=38;5;9:*.tbz2=38;5;9:*.tz=38;5;9:*.deb=38;5;9:*.rpm=38;5;9:*.jar=38;5;9:*.war=38;5;9:*.ear=38;5;9:*.sar=38;5;9:*.rar=38;5;9:*.alz=38;5;9:*.ace=38;5;9:*.zoo=38;5;9:*.cpio=38;5;9:*.7z=38;5;9:*.rz=38;5;9:*.cab=38;5;9:*.jpg=38;5;13:*.jpeg=38;5;13:*.gif=38;5;13:*.bmp=38;5;13:*.pbm=38;5;13:*.pgm=38;5;13:*.ppm=38;5;13:*.tga=38;5;13:*.xbm=38;5;13:*.xpm=38;5;13:*.tif=38;5;13:*.tiff=38;5;13:*.png=38;5;13:*.svg=38;5;13:*.svgz=38;5;13:*.mng=38;5;13:*.pcx=38;5;13:*.mov=38;5;13:*.mpg=38;5;13:*.mpeg=38;5;13:*.m2v=38;5;13:*.mkv=38;5;13:*.webm=38;5;13:*.ogm=38;5;13:*.mp4=38;5;13:*.m4v=38;5;13:*.mp4v=38;5;13:*.vob=38;5;13:*.qt=38;5;13:*.nuv=38;5;13:*.wmv=38;5;13:*.asf=38;5;13:*.rm=38;5;13:*.rmvb=38;5;13:*.flc=38;5;13:*.avi=38;5;13:*.fli=38;5;13:*.flv=38;5;13:*.gl=38;5;13:*.dl=38;5;13:*.xcf=38;5;13:*.xwd=38;5;13:*.yuv=38;5;13:*.cgm=38;5;13:*.emf=38;5;13:*.axv=38;5;13:*.anx=38;5;13:*.ogv=38;5;13:*.ogx=38;5;13:*.aac=38;5;45:*.au=38;5;45:*.flac=38;5;45:*.mid=38;5;45:*.midi=38;5;45:*.mka=38;5;45:*.mp3=38;5;45:*.mpc=38;5;45:*.ogg=38;5;45:*.ra=38;5;45:*.wav=38;5;45:*.axa=38;5;45:*.oga=38;5;45:*.spx=38;5;45:*.xspf=38;5;45:',
	MAIL: '/var/spool/mail/kadmin',
	PATH: '/root/.nvm/versions/node/v12.13.1/lib/node_modules/npm/node_modules/npm-lifecycle/node-gyp-bin:/var/www/html/fdc360/front/node_modules/.bin:/root/.nvm/versions/node/v12.13.1/bin:/usr/local/bin:/usr/bin:/usr/local/sbin:/usr/sbin:/home/kadmin/.local/bin:/home/kadmin/bin',
	_: '/var/www/html/fdc360/front/node_modules/.bin/ts-node',
	PWD: '/var/www/html/fdc360/front',
	LANG: 'en_US.UTF-8',
	SELINUX_LEVEL_REQUESTED: '',
	HISTCONTROL: 'ignoredups',
	SHLVL: '3',
	HOME: '/root',
	LOGNAME: 'kadmin',
	CVS_RSH: 'ssh',
	SSH_CONNECTION: '187.20.120.128 49233 209.172.47.47 9732',
	LESSOPEN: '||/usr/bin/lesspipe.sh %s',
	NVM_BIN: '/root/.nvm/versions/node/v12.13.1/bin',
	XDG_RUNTIME_DIR: '/run/user/1000',
	production: 'true',
	debug: 'false',
	ADMIN_USERNAME: 'admin',
	ADMIN_PASSWORD: 'qas159kuk@c',
	API_URL: 'https://aval360qas.kukac.com.br:3333/',
	API_KEY: 'AVAL360_API_KEY_123456789',
	LOGO: 'FDC.png'
};